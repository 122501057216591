import * as React from "react";
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout";
import Seo from "../components/seo";
import HeroSection from "../components/Home/HeroSection";
import AboutUs from "../components/Home/AboutUs";
import OurServices from "../components/Home/OurServices";
import Affiliates from "../components/Home/Affiliates";
// import Stats from "../components/Home/Stats";
import "animate.css";

import { ParallaxProvider } from 'react-scroll-parallax';

const IndexPage = () => (
  <ParallaxProvider>
    <Layout>
    <Seo title="Home" />
      <HeroSection />
      <AboutUs />
      <OurServices />
      {/* <Stats /> */}
      {/* <Affiliates /> */}
      {/* <ContactUs />
      <Inquiries /> */}
    </Layout>
  </ParallaxProvider>
);

export default IndexPage;
